<template>
  <el-cascader
    class="tag-cascader"
    v-model="selectIds"
    :options="options"
    :props="setting"
    filterable
    clearable
    @change="onChange"
    @blur="onBlur"
    @focus="onFocus"
    @visible-change="onVisibleChange"
  >
    <template slot-scope="{ node, data }">
      <span>{{ data.name }}</span>
      <span v-if="!node.isLeaf"> ({{ data.subs.length }}) </span>
    </template>
  </el-cascader>
</template>

<script>
import { GetSolutionsTagsTree } from "../api";
export default {
  props: {
    value: {
      type: [Array, Number],
      default: () => null,
    },
    cascaderProps: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      options: [],
      selectIds: null,
      setting: {
        multiple: false,
        checkStrictly: true,
        emitPath: false,
        children: "subs",
        label: "name",
        value: "id",
      },
    };
  },
  created() {
    this.initOptions();
    this.asyncSetting();
  },
  watch: {
    value: {
      handler(data) {
        // checkStrictly=true的时候，无论值是否开启多选，值都是数组
        if (this.cascaderProps.multiple || this.cascaderProps.emitPath) {
          this.selectIds = [...data];
        } else {
          this.selectIds = data;
        }
      },
      immediate: true,
    },
  },
  methods: {
    asyncSetting() {
      if (Object.keys(this.cascaderProps).length > 0) {
        for (const key in this.cascaderProps) {
          this.setting[key] = this.cascaderProps[key];
        }
      }
    },
    initOptions() {
      GetSolutionsTagsTree({
        disabled: false,
      }).then((res) => {
        this.options = this.buildOptions(res.data);
      });
    },
    buildOptions(data = []) {
      if (data && data.length > 0) {
        return data.map((item) => {
          let _item = {
            id: item.id,
            name: item.name.cn,
            disabled: item.disabled,
          };
          if (item.subs.length > 0) {
            _item["subs"] = this.buildOptions(item.subs);
          }
          return _item;
        });
      } else {
        return [];
      }
    },
    onChange(e) {
      this.$emit("input", this.selectIds);
      this.$emit("change", e);
    },
    onBlur(e) {
      this.$emit("blur", e);
    },
    onFocus(e) {
      this.$emit("focus", e);
    },
    onVisibleChange(e) {
      this.$emit("visible-change", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-cascader {
  width: 100%;
}
</style>
