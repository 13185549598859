<template>
  <section class="base-page">
    <keep-alive><FilterSection @change="onFilterChange"/></keep-alive>
    <el-alert
      class="mt-t"
      title="当前表格支持直接双击字段修改"
      type="info"
      center
      show-icon
      :closable="false"
    >
    </el-alert>
    <BasicTable
      showSelection
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onClearAndRefresh="onClearAndRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl>
        <el-button
          size="small"
          class="mt-r"
          type="primary"
          icon="el-icon-document-add"
          @click="addRow"
          v-if="checkPermission(['PRODUCT_ADMIN'])"
          >新增</el-button
        >
        <el-checkbox class="mt-l mt-r" v-model="showDisable" @change="onSearch"
          >显示禁用</el-checkbox
        >
        &nbsp; &nbsp;
      </template>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'lastModifiedAt'">{{
              scope.row.lastModifiedAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'disabled'">
              <el-tag
                :type="scope.row.disabled ? 'danger' : 'success'"
                @click="toggleDisableRow(scope.row, scope.$index)"
                >{{ scope.row.disabled ? "已禁用" : "已启用" }}</el-tag
              >
            </span>
            <span v-else-if="column.prop === 'nameCn'">
              <InputRow
                :title="scope.row.canUpdate ? '双击修改' : ''"
                v-model="scope.row.name.cn"
                @input="onEditDone($event, scope.row)"
              />
            </span>
            <span v-else-if="column.prop === 'nameEn'">
              <InputRow
                :title="scope.row.canUpdate ? '双击修改' : ''"
                v-model="scope.row.name.en"
                @input="onEditDone($event, scope.row)"
              />
            </span>
            <span v-else-if="column.prop === 'seq'">
              <InputRow
                :title="scope.row.canUpdate ? '双击修改' : ''"
                v-model="scope.row.seq"
                @input="onEditDone($event, scope.row)"
              />
            </span>
            <span v-else-if="column.prop === 'parentName'">
              <SolutionsTagCascader
                v-if="scope.row.isEdit"
                v-model="scope.row.parentId"
                @change="onEditParentDone($event, scope.row)"
              />
              <span v-else @dblclick="scope.row.isEdit = !scope.row.isEdit">{{
                scope.row.parentName ? scope.row.parentName.cn : "[未设置]"
              }}</span>
            </span>
            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-dropdown>
              <span class="el-dropdown-link drop-down-menu-link">
                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-if="scope.row.canUpdate"
                  @click.native.prevent="editRow(scope.row, scope.$index)"
                  >编辑</el-dropdown-item
                >

                <el-dropdown-item
                  v-if="scope.row.canDisable"
                  :class="[scope.row.disabled ? 'text-success' : 'text-danger']"
                  @click.native.prevent="
                    toggleDisableRow(scope.row, scope.$index)
                  "
                >
                  {{ scope.row.disabled ? "启用" : "禁用" }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <div class="multiple-control-row">
          <el-button
            class="mt-r"
            size="small"
            @click="onMultipleToggleDisable(false)"
            :disabled="multipleSelected.length <= 0"
            >批量启用 {{ multipleSelected.length }}</el-button
          >
          <el-button
            class="mt-r"
            size="small"
            @click="onMultipleToggleDisable(true)"
            :disabled="multipleSelected.length <= 0"
            >批量禁用 {{ multipleSelected.length }}</el-button
          >
        </div>
      </template>
    </BasicTable>

    <MultipleForm
      :show="formDialog"
      @close="onFormClose"
      @success="onFormSuccess"
    />
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import {
  GetSolutionsTagsList,
  PutSolutionsTags,
  ToogleDisableByIds,
  GetDisableCheck,
  GetUpdateCheck,
} from "./api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import {
  GetTableOrderParams,
  onClearFilter,
  FormatMultipleItemIds,
  GetTableDisableMessage,
} from "@/utils/table";
import MultipleForm from "./components/MultipleForm";
import FilterSection from "./components/FilterSection";
import SolutionsTagCascader from "./components/SolutionsTagCascader";
import InputRow from "@/components/InputRow";
import config from "@/config";
export default {
  name: "ArticleTagList",
  components: {
    BasicTable,
    FilterSection,
    MultipleForm,
    InputRow,
    SolutionsTagCascader,
  },
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      multipleFormDialog: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "seq",
      orderDirection: "descending",
      currentId: null,
      multipleSelected: [],
      showDisable: false,
      orderPropObj: {},
    };
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        this.tableColumns = [
          {
            prop: "disabled",
            label: "状态",
            showColumn: true,
            width: 100,
            align: "center",
            fixed: "left",
            sortable: "custom",
          },

          {
            prop: "nameCn",
            label: "中文名称",
            minWidth: 160,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "nameEn",
            label: "英文名称",
            minWidth: 160,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },

          {
            prop: "parentName",
            label: "父级",
            minWidth: 180,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },

          {
            prop: "seq",
            label: "次序",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建日期",
            showColumn: true,
            width: 200,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "createdByName",
            label: "创建人",
            showColumn: true,
            align: "center",
            minWidth: 150,
            sortable: "custom",
          },
          {
            prop: "lastModifiedAt",
            label: "更新日期",
            showColumn: true,
            width: 200,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "lastModifiedByName",
            label: "更新人",
            showColumn: true,
            align: "center",
            minWidth: 150,
            sortable: "custom",
          },
          {
            prop: "id",
            label: "ID",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            align: "center",
            showColumn: false,
            label: "操作",
            width: 100,
          },
        ];
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },

    onSearch() {
      this.page = 0;
      this.getTableData();
    },
    getTableData() {
      this.tableLoading = true;
      GetSolutionsTagsList({
        page: this.page,
        size: this.size,
        sort: GetTableOrderParams(
          this.orderProp,
          this.orderDirection,
          this.orderPropObj
        ),
        disabled: this.showDisable,
        ...this.tableFilters,
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.tableData = this.tableData.map((item) => {
            return {
              ...item,
              canUpdate: true,
              canDisable: true,
              isEdit: false,
            };
          });
          this.onUpdateCheck();
          this.onDisableCheck();
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.getTableData();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.page = 0;
      this.onSearch();
    },
    onClearAndRefresh() {
      this.orderProp = "seq";
      this.orderDirection = "descending";
      this.onResetFilter();
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.page = 0;
      this.onRefresh();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "seq";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.onRefresh();
    },
    addRow() {
      this.currentId = null;
      this.formDialog = true;
    },
    editRow(row) {
      if (!row.canUpdate) {
        this.$message.warning("没有权限执行此操作!");
        return false;
      }
      this.currentId = row.id;
      this.formDialog = true;
    },
    async onUpdateCheck() {
      try {
        let ids = this.tableData.map((item) => item.id);
        if (ids.length > 0) {
          let res = await GetUpdateCheck(ids);
          this.tableData = this.tableData.map((item) => {
            item.canUpdate = res.data;
            return item;
          });
        }
      } catch (error) {
        this.tableData = this.tableData.map((item) => {
          item.canUpdate = true;
          return item;
        });
      }
    },
    async onDisableCheck() {
      try {
        let ids = this.tableData.map((item) => item.id);
        if (ids.length > 0) {
          let res = await GetDisableCheck(ids);
          this.tableData = this.tableData.map((item) => {
            item.canDisable = res.data;
            return item;
          });
        }
      } catch (error) {
        this.tableData = this.tableData.map((item) => {
          item.canDisable = true;
          return item;
        });
      }
    },
    onFormCancel() {
      this.currentId = null;
      this.multipleFormDialog = false;
      this.formDialog = false;
    },
    // 批量：启用/禁用
    onMultipleToggleDisable(flag = false) {
      if (this.multipleSelected.length <= 0) {
        return false;
      }
      let { ids, nameString, unableControl } = FormatMultipleItemIds({
        multipleSelectes: this.multipleSelected,
        nameKey: "id",
      });
      let message = GetTableDisableMessage({
        total: this.multipleSelected.length,
        ableControlCount: ids.length,
        unableControlCount: unableControl.length,
        nameString: nameString,
        flag: flag,
        ableText: "启用",
        unAbleText: "禁用",
      });
      this.$msgbox({
        title: `${flag ? "禁用" : "启用"}提示`,
        message: message,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            if (ids.length <= 0) {
              this.$message.info("无有效数据可操作");
              done();
              return;
            }
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            ToogleDisableByIds(ids, flag)
              .then(() => {
                this.$message.success("操作成功");
                this.multipleSelected = [];
                this.onRefresh();
              })
              .catch((err) => {
                ShowApiError("操作请求错误",err );
              })
              .finally(() => {
                done();
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
              });
          } else {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "";
            done();
          }
        },
      });
    },

    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    toggleDisableRow(row) {
      if (row.canDisable) {
        ToogleDisableByIds([row.id], !row.disabled).then(() => {
          row.disabled = !row.disabled;
          this.onRefresh();
          this.$message.success("操作成功!");
        });
      } else {
        this.$message.warning("没有权限执行此操作!");
      }
    },
    onEditParentDone(value, row) {
      this.onEditDone("done", row);
    },

    async onEditDone(value, row) {
      if (!row.canUpdate) {
        return false;
      }
      try {
        if (value && value !== "") {
          let _name = { ...row.name };
          delete _name.i18n;
          let form = {
            id: row.id,
            name: { ...row.name },
            parentId: row.parentId,
            seq: row.seq,
          };
          await PutSolutionsTags([form]);
          this.$message.success("修改成功");
        }
        this.onRefresh();
      } catch (error) {
        ShowApiError("修改失败", error);
      }
    },

    onFormClose() {
      this.formDialog = false;
    },

    onFormSuccess() {
      if (isEmprty(this.currentId)) {
        this.page = 0;
        this.formDialog = false;
      }
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-image__inner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
